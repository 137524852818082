//success flash card
#flash-card-background{
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1111;
    display : flex;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background-color: #11111166;
    // filter: blur(5px);
  }

  #flash-card{
    width:fit-content;
    height: fit-content;
    min-width: 300px;
    min-height: 320px;
    background-color: #ffffff;
    z-index: 99999999;
    position: fixed;
    border-radius: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  #flash-card-content{
    width: 80%;
    height: 80%;
    // background-color: #6ba7e6;
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-direction: column;
    line-height: 40px;
  }

  #flash-card-tick-icon-img-container{
    width: 55px;
  }

  #flash-card-heading-msg{
    margin-top: 10px;
    color: #050505;
    font-weight: 700;
    font-size: 13px;
  }

  .flash-card-inner-text-container{
    width: 95%;
    display: flex;
    justify-content: space-between;


  }

  #flash-card-ammount :before{
      content: '';
      position: absolute;
      bottom: 185px;
      right: 32px;
      height: 1px;
      width: calc(95% - 50px);
      background: #e6e2e2;
  }
  // #flash-card-date{
  //   content: '';
  //     position: absolute;
  //     bottom: 185px;
  //     right: 32px;
  //     height: 1px;
  //     width: calc(95% - 50px);
  //     background: #e6e2e2;
  // }
  // .flash-card-inner-text-container :before{
  //   content: '\A';
  //   white-space: pre;
  // }

  .flash-card-inner-text{
    font-size: 11px;
    color: #a4a4a4;
    font-weight: 700;
  }
  .flash-card-inner{
    font-size: 11px;
    color: #2b2b2b;
    font-weight: 700;
  }

  #flash-card-close-button{
    margin-top: 15px;
    width: 90px;
    height: 30px;
    background-color: #24d0a1;
    text-align: center;
    display: flex;
    align-items:center;
    justify-content: center;
    font-size: 15px;
    color: #e0f8f2;
    border-radius: 1.2rem;
    cursor: pointer;
  }

  // error flash card

  #error-flash-card-background{
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1111;
    display : flex;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background-color: #11111166;
    // filter: blur(5px);
  }

  #error-flash-card{
    width:fit-content;
    height: fit-content;
    min-width: 260px;
    min-height: 270px;
    background-color: #ffffff;
    z-index: 99999999;
    position: fixed;
    border-radius: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  #error-flash-card-content{
    width: 80%;
    height: 80%;
    // background-color: #6ba7e6;
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-direction: column;
    line-height: 40px;
  }

  #error-flash-card-tick-icon-img-container{
    width: 55px;
  }

  #error-flash-card-heading-msg{
    margin-top: 10px;
    color: #050505;
    font-weight: 700;
    font-size: 15px;
  }
  #error-flash-card-subheading-msg{
    margin-top: 15px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    color: #aca5a5;
    font-weight: 700;
    font-size: 13px;
  }

  
  #error-flash-card-close-button{
    // margin-top: 15px;
    width: 90px;
    height: 30px;
    background-color: #24d0a1;
    text-align: center;
    display: flex;
    align-items:center;
    justify-content: center;
    font-size: 15px;
    color: #e0f8f2;
    border-radius: 1.2rem;
    cursor: pointer;
  }

  //request flash card

  #request-flash-card-background{
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1111;
    display : flex;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background-color: #11111166;
    // filter: blur(5px);
  }

  #request-flash-card{
    width:fit-content;
    height: fit-content;
    min-width: 320px;
    min-height: 210px;
    background-color: #ffffff;
    z-index: 99999999;
    position: fixed;
    border-radius: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  #request-flash-card-content{
    width: 80%;
    height: 80%;
    // background-color: #6ba7e6;
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-direction: column;
    line-height: 40px;
  }

  #request-flash-card-tick-icon-img-container{
    width: 55px;
  }

  #request-flash-card-heading-msg{
    margin-top: 10px;
    color: #050505;
    font-weight: 700;
    font-size: 14px;
  }
  // #request-flash-card-subheading-msg{
  //   margin-top: 15px;
  //   margin-bottom: 0px;
  //   padding-bottom: 0px;
  //   color: #aca5a5;
  //   font-weight: 700;
  //   font-size: 13px;
  // }

  
  #request-flash-card-close-button{
    margin-top: 25px;
    width: 90px;
    height: 30px;
    background-color: #24d0a1;
    text-align: center;
    display: flex;
    align-items:center;
    justify-content: center;
    font-size: 15px;
    color: #e0f8f2;
    border-radius: 1.2rem;
    cursor: pointer;
  }



  #second-flash-card-background{
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1111;
    display : flex;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background-color: #11111166;
    // filter: blur(5px);
  }

  #second-flash-card{
    width:fit-content;
    height: fit-content;
    min-width: 300px;
    min-height: 320px;
    background-color: #ffffff;
    z-index: 99999999;
    position: fixed;
    border-radius: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  #second-flash-card-content{
    width: 80%;
    height: 80%;
    // background-color: #6ba7e6;
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-direction: column;
    line-height: 40px;
  }

  #second-flash-card-tick-icon-img-container{
    width: 55px;
  }

  #second-flash-card-heading-msg{
    margin-top: 10px;
    color: #050505;
    font-weight: 700;
    font-size: 18px;
  }

  #second-flash-card-content-msg-container{
    width: 100%;
    margin-top: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .second-flash-card-content-msg{
    line-height: 2.5;
    color: #050505;
    font-weight: 600;
    font-size: 13px;
  }

  #second-flash-card-close-button{
    margin-top: 15px;
    width: 90px;
    height: 30px;
    background-color: #24d0a1;
    text-align: center;
    display: flex;
    align-items:center;
    justify-content: center;
    font-size: 15px;
    color: #e0f8f2;
    border-radius: 1.2rem;
    cursor: pointer;
  }