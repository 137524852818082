// #forgot-content-inner-img{
//    width: 10%;
// }
// #forgot-content-inner-img-container{
//     display: flex;
//     align-items: flex-end;
//     justify-content: center;
// }
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700;800;900&display=swap');
*{
    font-family:'Montserrat', sans-serif;
}
#forgot-password-background{
    background: #4f4b5e;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
#forgot-password-content-container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#forgot-password-content{
    width: 70%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #5d5576;
    border-radius: 1.2rem;
    /* min-width: 1075px;; */
}

#forgot-password-content-inner-container{
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#forgot-password-content-inner{
    width: 80%;
    height: 70%;
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    justify-content: center;
    /* margin-top: 20px; */
    /* margin-left: 50px; */
    /* min-width: 587px; */
    margin-left: 35px;
    margin-bottom: 8px;
}

#forgot-password-content-inner-img-container{
    width: 250px;
    /* height: 30%; */
    margin-bottom: 35px;
}

#forgot-password-content-inner-img{
    width: 100%;
}

#forgot-password-content-inner-heading-container{
    width: 398px;
    /* height: 37%; */
    font-size: 20px;
    color: aliceblue;
    /* margin-bottom: 0px; */
}
#forgot-password-content-inner-sub-heading-container{
    /* height: 33%; */
    font-size: 20px;
    width: 398px;
    margin-top: 25px;
    color: aliceblue;
    font-size: 17px;
}

#forgot-password-content-form-card-container{
    width: 80%;
    height: 50%;
    display: flex;
    justify-content: center;
}
// #forgot-password-background{
//     background: #4f4b5e;
//     width: 100vw;
//     height: 100vh;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }

#forgot-password-card{
    display: flex;
    align-items: center;
    justify-content:space-around;
    flex-direction: column;
    width: 400px;
    height: 300px;
    background-color: white;
    border-radius: 1.2rem;
    /* min-width: 415px; */
}

#forgot-password-card-inner{
    width: 80%;
    height: 72%;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
}

#forgot-password-heading-container{
    width: 100%;
    height: 40%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    /* justify-content: space-around; */
}

#forgot-password-heading{
    color: black;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 5px;
}

#forgot-password-sub-heading{
    font-weight: 400;
    font-size: 11px;
}

#forgot-password-form-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 60%;
}

#forgot-password-form{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

#forgot-password-email-input-container{
    width: 100%;
    height: 35%;
    border-radius: 1.2rem;
}

#forgot-password-email-input{
    width: 100%;
    height: 100%;
    border-radius: 1.2rem;
    background-color: white;
    padding: 15px;
    border: 1px solid rgb(190, 186, 186);
    outline: none;
}

#forgot-password-email-input:focus{
    /* background-color: rgb(211, 230, 235); */
    border: 1px solid black;;
}

#forgot-password-button-container{
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 32%;
}

#forgot-password-button{
    width: 37%;
    height: 100%;
    border-radius: 1.5rem;
    /* border: 1px solid black; */
    /* outline: none; */
    /* border: 1px solid white; */
    border: none;
    background: #24d0a1;
    color: white;
    font-weight: 700;
    font-size: 16px;
}

#forgot-password-button:hover{
    cursor: pointer;
    box-shadow: 0 0.5em 0.5em -0.4em #24d0a1;
    transform: translateY(-0.25em);
}

@media only screen and (max-width: 1543px) {
    #forgot-password-content{
        width: 90%;
    }
}
@media only screen and (max-width: 1333px) {
    #forgot-password-content-inner-container{
        width: 60%;
    }
}
@media only screen and (max-width: 1115px) {
    /* #signIn-content{
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    } */
    /* #signIn-content-inner-container{
        display: none;
    } */
    #forgot-password-content{
        width: 100%;
    }
    #forgot-password-content-inner-container{
        width: 60%;
    }
    #forgot-password-content-inner{
        width: 80%;
    }
    /* #signIn-content-inner-img-container{
        width: 50%;
    } */
  }

  @media only screen and (max-width: 870px) {
    #forgot-password-content{
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
#forgot-password-content-inner-container{
    width: 100%;
}
    #forgot-password-content-inner{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0px;
        /* margin-left: 220px; */
    }  
    #forgot-password-content-inner-img-container{
        width: 250px;
        display: flex;
        align-items: flex-start;
    }
    #forgot-password-content-inner-heading-container{width: 363px;
    text-align: center;}
    #forgot-password-content-inner-sub-heading-container{width: 363px;
    text-align: center;}
    #forgot-password-content-form-card-container{
        margin-bottom: 25px;
    }
  }





#link-sent-background{
    background: #4f4b5e;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
#link-sent-content-container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#link-sent-content{
    width: 70%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #5d5576;
    border-radius: 1.2rem;
    /* min-width: 1075px;; */
}

#link-sent-content-inner-container{
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#link-sent-content-inner{
    width: 80%;
    height: 70%;
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    justify-content: center;
    /* margin-top: 20px; */
    /* margin-left: 50px; */
    /* min-width: 587px; */
    margin-left: 35px;
    margin-bottom: 8px;
}

#link-sent-content-inner-img-container{
    width: 250px;
    /* height: 30%; */
    margin-bottom: 35px;
}

#link-sent-content-inner-img{
    width: 100%;
}

#link-sent-content-inner-heading-container{
    width: 398px;
    /* height: 37%; */
    font-size: 20px;
    color: aliceblue;
    /* margin-bottom: 0px; */
}
#link-sent-content-inner-sub-heading-container{
    /* height: 33%; */
    font-size: 20px;
    width: 398px;
    margin-top: 25px;
    color: aliceblue;
    font-size: 17px;
}

#link-sent-content-form-card-container{
    width: 80%;
    height: 50%;
    display: flex;
    justify-content: center;
}

#link-sent-card{
    display: flex;
    align-items: center;
    justify-content:center;
    flex-direction: column;
    width: 380px;
    height: 320px;
    background-color: white;
    border-radius: 1.2rem;
    /* min-width: 415px; */
}

#link-sent-card-inner{
    width: 90%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}

#link-sent-img-container{
    /* width: 25%; */
    height: 40%;
}

#link-sent-text-container{
    width: 100%;
    height: 60%;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

#link-sent-heading{
    color: black;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 15px;
}


#link-sent-card-content{
    font-weight: 400;
    font-size: 14px;
}

#link-sent-button-container{
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#link-sent-resend-link-button{
    width: 48%;
    height: 100%;
    border-radius: 1.5rem;
    border: none;
    background: white;
    color: #24d0a1;
    font-weight: 700;
    font-size: 16px;
    border: 1px solid rgb(66, 65, 65);
}
/* #link-sent-resend-link-button:focus {
  box-shadow: inset 6.5em 0 0 0 #24d0a1;
} */
#link-sent-resend-link-button:hover{
    cursor: pointer;
    box-shadow: 0 0.5em 0.5em -0.4em #24d0a1;
    transform: translateY(-0.25em);
    /* box-shadow: inset 6.5em 0 0 0 #24d0a1; */
}

#link-sent-signIn-button{
    width: 48%;
    height: 100%;
    border-radius: 1.5rem;
    border: none;
    background: #24d0a1;
    color: white;
    font-weight: 700;
    font-size: 16px;
}

#link-sent-signIn-button:hover{
    cursor: pointer;
    box-shadow: 0 0.5em 0.5em -0.4em #24d0a1;
    transform: translateY(-0.25em);
}

@media only screen and (max-width: 1543px) {
    #link-sent-content{
        width: 90%;
    }
}
@media only screen and (max-width: 1333px) {
    #link-sent-content-inner-container{
        width: 60%;
    }
}
@media only screen and (max-width: 1115px) {
    /* #signIn-content{
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    } */
    /* #signIn-content-inner-container{
        display: none;
    } */
    #link-sent-content{
        width: 100%;
    }
    #link-sent-content-inner-container{
        width: 60%;
    }
    #link-sent-content-inner{
        width: 80%;
    }
    /* #signIn-content-inner-img-container{
        width: 50%;
    } */
  }

  @media only screen and (max-width: 870px) {
    #link-sent-content{
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
#link-sent-content-inner-container{
    width: 100%;
}
    #link-sent-content-inner{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0px;
        /* margin-left: 220px; */
    }  
    #link-sent-content-inner-img-container{
        width: 250px;
        display: flex;
        align-items: flex-start;
    }
    #link-sent-content-inner-heading-container{width: 363px;
    text-align: center;}
    #link-sent-content-inner-sub-heading-container{width: 363px;
    text-align: center;}
    #link-sent-content-form-card-container{
        margin-bottom: 25px;
    }
  }