.card{
    background: $card-black-background;
    border: 0;
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    box-shadow: $box-shadow;
    // padding-left: 150px !important;

    label{
      color: rgba($white, 0.6);
    }

    .card-title {
      margin-bottom: .75rem;
    }

    .card-body{
      padding: 15px;

      &.table-full-width{
        padding-left: 0;
        padding-right: 0;
      }

      .card-title{
        color: $white;
        text-transform: inherit;
        font-weight: $font-weight-light;
        margin-bottom: .75rem;
      }

      .card-description, .card-category{
        color: rgba($white, 0.6);
      }

    }

    .card-header{
      &:not([data-background-color]){
        background-color: transparent;
      }
      padding: 15px 15px 0;
      border: 0;
      color: rgba($white,0.8);

      .card-title{
          color: $white;
          font-weight: 100;
      }

      .card-category{
        color: $dark-gray;
        margin-bottom: 5px;
        font-weight: 300;
      }
    }

    .map{
        border-radius: $border-radius-sm;

        &.map-big{
          height: 420px;
        }
    }

    &.card-white{
      background: $white;

      .card-title{
        color: $black;
      }
      .card-category, .stats{
        color: $card-stats-gray;
      }

      //style for inputs

      @include form-control-placeholder(rgba($black,0.4), 1);
        .has-danger{
          .form-control, .input-group-prepend .input-group-text{
            border-color: $danger-states;
          }
        }

        .input-group-prepend .input-group-text{
          border-color: rgba($black-states,0.2);
          color: $black-states;
        }

        .form-control{
          color: $black;
          border-color: rgba($black-states,0.2);
          &:focus{
            border-color: $primary;
          }
        }
        label:not(.btn){
          color: $default;
        }

      .form-group.no-border,
      .input-group.no-border {
          .form-control,
          .form-control + .input-group-prepend .input-group-text,
          .form-control + .input-group-append .input-group-text,
          .input-group-prepend .input-group-text,
          .input-group-append .input-group-text{
            background-color: $opacity-gray-3;
              &:focus,
              &:active,
              &:active{
                background-color: $opacity-gray-5;
              }
          }

          .form-control {
              &:focus {
                  & + .input-group-prepend .input-group-text,
                  & + .input-group-append .input-group-text{
                    background-color: $transparent-bg;
                  }
              }
          }


      }

      .input-group[disabled]{
          .input-group-prepend .input-group-text,
          .input-group-append .input-group-text{
              background-color: $black;
          }
      }

      .form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control{
        background: $light-gray;
        border-color: rgba($black-states,0.3);
      }

      .input-group-focus{
          .input-group-prepend .input-group-text,
          .input-group-append .input-group-text,
          .form-control{
            background-color: $white;
            border-color: $primary;
          }

          &.no-border{
              .input-group-prepend .input-group-text,
              .input-group-append .input-group-text{

                  background-color: $opacity-gray-5;
              }
          }
      }
      .input-group-prepend .input-group-text {
          border-right: none;
      }

      .input-group-append .input-group-text {
          border-left: none;
      }

      .has-danger .form-control:focus, .has-success.input-group-focus .input-group-append .input-group-text, .has-success.input-group-focus .input-group-prepend .input-group-text {
        border-color: $danger-states;
      }

      .has-success .form-control:focus, .has-success.input-group-focus .input-group-append .input-group-text, .has-success.input-group-focus .input-group-prepend .input-group-text {
        border-color: darken($success, 10%);
      }
    }


    &.card-plain {
      background: transparent;
      box-shadow: none;
    }

    .image{
        overflow: hidden;
        height: 200px;
        position: relative;
    }

    .avatar{
        width: 30px;
        height: 30px;
        overflow: hidden;
        border-radius: 50%;
        margin-bottom: 15px;
    }

    label{
        font-size: $font-size-sm;
        margin-bottom: 5px;

    }

    .card-footer{
        background-color: transparent;
        border: 0;
        padding: 15px;


        .stats{
            i{
                margin-right: 5px;
                position: relative;

            }
        }

        h6{
          margin-bottom: 0;
          padding: 7px 0;
        }
    }
}

.card-body{
  padding: $card-spacer-y;
}

@include media-breakpoint-down(sm) {
  .card.card-chart .card-header {
    .btn-group-toggle .tim-icons {
      font-size: .875rem;
      top: -1px;
    }
  }
}


.card-position{
  padding-left: 65px;
  // border-radius: 2.3rem !important;
}

.button-position{
  padding-left: 55px;
}
.card-custom{
  border-radius: 2.3rem !important;
  background: #5d5576;
}

.dashboardTitle {
  margin-left: 65px;
  position: relative;
  bottom: 20px;
}

.activity-card-title{
  margin-left:15px ;
}

.card-title{
  font-weight: 700 !important;
}

.card-header{
  margin-top: 10px!important;
}

.top-cards-row{
  width: auto;
  display: flex;
  // align-items: center;
  // justify-content: space-between;
  // width: 100%;
}
.top-cards-column{
  width: 329px;
  height: 123px;
  background-color: #8966fe;
  margin-right: 15px;
  border-radius: 1.2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  img{
    margin-left: 20px;
    margin-right:30px; 
  }

}

.column:first-of-type {
  margin-left: 31px;
}

.count-column{
  display: flex;
  flex-direction: column;
}

.heart-count{
  font-size: 25px;
}

.activity-content{
  color: #6ba7e6;
}
#history-content{
  color: #6ba7e6 !important;
  word-wrap: break-word !important;
  max-width: 350px !important;
  width: 350px !important; 
}
#activity-header th{
  background-color: #685d8c;
}
#activity-header th:first-child{
  border-radius: 1.2rem 0 0 1.2rem;
}
#activity-header th:last-child{
  border-radius: 0 1.2rem 1.2rem 0 ;
}
#log-header th:last-child{
  border-radius: 0 1.2rem 1.2rem 0 ;
}
#log-header th{
  background-color: #685d8c;
}
#log-header th:first-child{
  border-radius: 1.2rem 0 0 1.2rem;
}
#report-header th{
  background-color: #685d8c;
}
#report-header th:first-child{
  border-radius: 1.2rem 0 0 1.2rem;
}
#report-header th:last-child{
  border-radius: 0 1.2rem 1.2rem 0 ;
}
#claimApproval-header th{
  background-color: #685d8c;
}
#claimApproval-header th:first-child{
  border-radius: 1.2rem 0 0 1.2rem;
}
#claimApproval-header th:last-child{
  border-radius: 0 1.2rem 1.2rem 0 ;
}
#history-header th{
  background-color: #685d8c;
}
#history-header th:first-child{
  border-radius: 1.2rem 0 0 1.2rem;
}
#history-header th:last-child{
  border-radius: 0 1.2rem 1.2rem 0 ;
}

#activity-top-row{
  th{
  font-weight: 900!important;
  font-size: 13px !important;
  }
}
#log-top-row{
  th{
  font-weight: 900!important;
  font-size: 13px !important;
  }
}
#history-top-row{
  th{
    font-weight: 900!important;
    font-size: 13px !important;
  }
}
#claimApproval-top-row{
  th{
    font-weight: 900!important;
    font-size: 13px !important;
  }
}
#report-top-row{
  th{
    font-weight: 900!important;
    font-size: 13px !important;
  }
}

#addHearts-card-title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 23px;
}

.card-title-heading{
  margin-left: 15px;
}
.addHearts-card-title-count{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  display: flex;
}
.addHearts-card-title-count-msg{
  font-size: 13px;
  font-weight: 400;
}
.addHearts-payment-button{
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 35px;
  background: #24d0a1;
  border-radius: 1.2rem;
  font-size: 15px;
  font-weight: 700;
  margin-left: 15px;
  cursor: pointer;
  // outline: none;
}
.addHearts-payment-button:hover{
  border-radius: 0.5px solid #24d0a1;
  background:#24d0a1 !important;
  box-shadow: 0 0.5em 0.5em -0.4em #24d0a1 !important;
  transform: translateY(-0.25em)!important;
}
#addHearts-card-main{
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}


.addHearts-container{
  margin-bottom: 15px;
}

.addHearts-container:first-of-type{
  margin-top: 35px;
}

.addHerats-heading-subheading{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
}
.addHearts-heading{
  // width: 500px;
  color: #8c879e;
  // font-weight: 100;
  margin-bottom: 3px;
  font-weight: 700;
}
.addHearts-subheading{
  color: #24d0a1;
  width: fit-content;
  cursor: pointer;
  font-size: 13px;
}


.addHearts-main-count-container{
  width: 500px;
}
.addHearts-main-count{
  background: #5d5576!important;
  border-radius: 1.2rem;
  border: 1px solid #756b96;
  padding: 0px 15px;
  font-weight: 500 !important;
  // color: #8c879e!important;
}

.refresh-container{
  display: flex;
  align-items: center;
  width: 105px;
  height: 30px;
  margin-right: 15px;
  border-radius: 1.2rem;
  background: #685d8c;
}

.refresh-container:hover{
  box-shadow: 0 0.5em 0.5em -0.4em #24d0a1 !important;
  transform: translateY(-0.25em)!important;
}
.refresh-icon-img-container{
  margin-right: 3px!important;
  margin-left: 5px;
}

.refresh-msg{
  margin-right: 5px;
}
// .refresh-icon-img{
//   width: 25px;
// }

#request-approved-msg{
  color: #36aa94 !important;
}
#request-pending-msg{
  color: #ddcf64 !important;
}
#request-rejected-msg{
  color: #e17b81 !important;
}
#request-msg{
  color: white !important;
}
#request-submit-button{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height:40px;
  border-radius: 1.2rem;
  cursor: pointer;
  margin-top: 15px;
  background-color: #24d0a1;
  font-size: 16px;
  color: white;
}
#request-submit-button:hover{
  border-radius: 0.5px solid #24d0a1;
  background:#24d0a1 !important;
  box-shadow: 0 0.5em 0.5em -0.4em #24d0a1 !important;
  transform: translateY(-0.25em)!important;
}

.link-content{
  padding-left: 0px !important;
}
.link-content-fields{
  font-size: 14px;
  color: rgb(240, 222, 222);
  font-weight: 700;
}

.link-content-input{
  border-radius: 1.2rem !important;
}

#totalcost{
  &::placeholder{
    color: rgb(192, 183, 183) !important;
  }
}

.edit-profile-card-title{
  margin-left: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 23px;
}

.user-profile-input{
  border-radius: 1.2rem;
  margin-left: 0px;
}
.edit-profile-save-button{
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 35px;
  background: #24d0a1;
  border-radius: 1.2rem;
  font-size: 15px;
  font-weight: 700;
  margin-right: 15px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
.edit-profile-save-button:hover{
  border-radius: 0.5px solid #24d0a1;
  background:#24d0a1 !important;
  box-shadow: 0 0.5em 0.5em -0.4em #24d0a1 !important;
  transform: translateY(-0.35em)!important;
}

.user-profile-input-container-row{
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 30px;
}

.user-profile-input-container-column{
  width: 45%;
}

.user-profile-input{
  width: 100%;
}

.user-profile-input-label{
  font-size: 13.5px !important;
  color: rgb(185, 179, 179) !important;
  margin-left: 10px !important;
}

@media only screen and (max-width: 1280px) {

  .card-custom{
    width:125%;
  }
  .user-profile-input-container-row{
    display: block;
    width: 100%;
    margin-left: 20px;
  }
  .user-profile-input-container-column{
    width: 90%;
  }
}

@media only screen and (max-width: 769px) {
  .card-custom{
    width:95%;
  }
}
  
// .edit-profile-rows{
//   line-height: 30px;
// }

// select.user-profile-input{
//   margin-right: 500px !important;
// }

// .user-profile-column-1{
//   margin-right: 80px;
//   margin-left: 40px;
// }

.claim-approval-columns{
  width: 225px !important;
  min-width: fit-content !important;
}
.claim-approval-columns-end{
  width: 150px !important;
}
#claim-approval-columns-button{
  // width: 150px !important;
  width: 100px !important;
  border-radius: 1.2rem !important;
  padding: 10px 0px 10px 0px !important;
  background:#24d0a1 !important;
}
#claim-approval-columns-button:hover{
  border-radius: 0.5px solid #24d0a1;
  background:#24d0a1 !important;
  box-shadow: 0 0.5em 0.5em -0.4em #24d0a1 !important;
  transform: translateY(-0.25em)!important;
}

#claim-approval-columns-button:active{
  background:#24d0a1 !important;
}
#claim-approval-columns-button:focus{
  background:#24d0a1 !important;
}
.report-card-title{
  margin-left:15px ;
}
.modal-header {
  // margin-right: 50px !important;
  margin: 0px !important;
  margin-top: 10px !important;
  margin-right: 10px !important;
  padding: 0px !important;
  font-weight: 700 !important;
  font-size: 20px !important;
}

#update-msg-button{
    width: 80px !important;
    padding: 10px 0px 10px 0px !important;
    border-radius: 1.2rem !important;
    background: #24d0a1 !important;
}
#update-msg-button:hover{
border-radius: 0.5px solid #24d0a1;
background:#24d0a1 !important;
box-shadow: 0 0.5em 0.5em -0.4em #24d0a1 !important;
transform: translateY(-0.25em)!important;
}


@media only screen and (max-width: 1084px) {
  .top-cards-row{
      display: flex;
      flex-direction: column;
      // margin-bottom: 50px;
  }
  .top-cards-column{
    margin-bottom: 10px;
    margin-left: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .top-cards-row{
    display: flex;
    flex-direction: row;
    // margin-bottom: 50px;
}
.top-cards-column{
  margin-bottom: 0px;
  margin-left: 0px;
}
}

@media only screen and (max-width: 648px) {
  .top-cards-row{
      display: flex;
      flex-direction: column;
      // margin-bottom: 50px;
  }
  .top-cards-column{
    margin-bottom: 10px;
    // margin-left: 50px;
  }
}