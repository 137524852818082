.manualColors,
.manualColors a {
  color: white;
}

.manualColors:nth-child(odd),
.manualColors:nth-child(odd) a {
  color: rgb(190, 190, 190);
}

.manualColors a:hover,
.manualColors a:focus {
  color: #e14eca;
}

.card-chart .chart-area {
  height: 320px !important;
}

/* #geoGraph {
  width: 100%;
  height: 80%;
} */

.tablesorter tbody td {
  color: whitesmoke;
  border-bottom: none;
}

/* .white-content .manualColors {
  color: black;
} */

#geoGraph {
  background-color: "#27293d";
}
