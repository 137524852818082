@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700;800;900&display=swap');
*{
    font-family:'Montserrat', sans-serif;
}
#signIn-background{
    background: #4f4b5e;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
#signIn-content-container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#signIn-content{
    width: 70%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #5d5576;
    border-radius: 1.2rem;
}

#signIn-content-inner-container{
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#signIn-content-inner{
    width: 80%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#signIn-content-inner-img-container{
    width: 250px;
    margin-bottom: 35px;
}

#signIn-content-inner-img{
    width: 100%;
}

#signIn-content-inner-heading-container{
    width: 398px;
    font-size: 20px;
    color: aliceblue;
    cursor: default;

    h1{
        margin-bottom: 17px;
    }
}
#signIn-content-inner-sub-heading-container{
    font-size: 20px;
    width: 398px;
    margin-top: 25px;
    color: aliceblue;
    font-size: 17px;
    cursor: default;
}

#about-us-button-container{
    margin-top: 25px ;
    width: 275px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #403d49;
    font-size: 22px;
    font-weight: 700;
    cursor: default;
}

#about-us-button{
    color: #24d0a1;
    font-weight: 700;
    transition: transform 0.3s ease-in-out;
}

#about-us-button:hover{
    cursor:pointer;
    transform: translateY(-5px);
    // box-shadow: 0 0.5em 0.5em -0.4em #24d0a1;
}

#signIn-content-form-card-container{
    width: 50%;
    height: 75%;
    display: flex;
    justify-content: center;
}

#signIn-content-form-card{
    display: flex;
    align-items: center;
    justify-content:space-around;
    flex-direction: column;
    width: 70%;
    height: 100%;
    background-color: white;
    border-radius: 1.2rem;
    min-width: 415px;
}

#signIn-content-form-card-inner{
    height: 80%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

#form-card-heading-container{
    width:80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#form-card-heading{
    font-size: 20px;
    font-weight: 900;
    color: rgb(44, 41, 41);
    cursor: default;
}

#form-card-sub-heading{
    font-size: 14px;
    font-weight: 700;
    color: rgb(83, 81, 81);
    display: none;
}

#form-card-input-container{
    height: 75%;
    width: 100%;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    flex-direction: column;
}

#form-card{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

#form-card-inputs{
    width: 100%;
    height: 60%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

#form-card-email-input-container{
    width: 80%;
    height: 35%;
    border-radius: 1.2rem;
}

#form-card-email-input{
    width: 100%;
    height: 100%;
    border-radius: 1.2rem;
    padding: 15px;
    border: 1px solid rgb(190, 186, 186);
    outline: none;
}

#form-card-password-input-container{
    width: 80%;
    height: 35%;
    border-radius: 1.2rem;
}

#form-card-password-input{
    width: 100%;
    height: 100%;
    border-radius: 1.2rem;
    background-color: white;
    padding: 15px;
    border: 1px solid rgb(190, 186, 186);
    outline: none;
}

#form-card-password-input:focus{
    /* background-color: rgb(211, 230, 235); */
    border: 1px solid black;;
}
#form-card-email-input:focus{
    /* background-color: rgb(211, 230, 235); */
    border: 1px solid black;;
}
/* #form-card-password-input::placeholder{

} */


#form-card-footer{
    width: 80%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#form-card-forgot-password{
    font-size: 15px;
    font-weight: 700;
    transition: transform 0.3s ease-in-out;
}
#form-card-forgot-password:hover{
    cursor: pointer;
    transform: translateY(-0.25em);
}
#form-card-forgot-password-text{
    color: black;
    font-size: 13.5px;
    font-weight: 700;
    
}
#form-card-forgot-password-text:hover{
    font-weight: 800;
    font-size: 13.5px;
}

#form-card-LogIn-button-container{
    width: 35%;
    height: 100%;
}

#form-card-LogIn-button{
    width: 100%;
    height: 100%;
    border-radius: 1.5rem;
    outline: none !important;
    border: none;
    background: #24d0a1;
    color: white;
    font-weight: 700;
    font-size: 16px;
    transition: transform 0.3s ease-in-out;
}

#form-card-LogIn-button:hover{
    cursor: pointer;
    box-shadow: 0 0.5em 0.5em -0.4em #24d0a1;
    transform: translateY(-0.25em);
}


@media only screen and (max-width: 1543px) {
    #signIn-content{
        width: 90%;
    }
}
@media only screen and (max-width: 1333px) {
    #signIn-content-inner-container{
        width: 60%;
    }
}
@media only screen and (max-width: 1115px) {
    /* #signIn-content{
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    } */
    /* #signIn-content-inner-container{
        display: none;
    } */
    #signIn-content{
        width: 100%;
    }
    #signIn-content-inner-container{
        width: 60%;
    }
    #signIn-content-inner{
        width: 80%;
    }
    /* #signIn-content-inner-img-container{
        width: 50%;
    } */
  }

  @media only screen and (max-width: 870px) {
    #signIn-content{
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
#signIn-content-inner-container{
    width: 100%;
}
    #signIn-content-inner{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        /* margin-left: 220px; */
    }  
    #signIn-content-inner-img-container{
        width: 250px;
        display: flex;
        align-items: flex-start;
    }
    #signIn-content-inner-heading-container{width: 363px;
    text-align: center;}
    #signIn-content-inner-sub-heading-container{width: 363px;
    text-align: center;}
    #signIn-content-form-card-container{
        margin-bottom: 25px;
    }
  }