@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700;800;900&display=swap');
*{
    font-family:'Montserrat', sans-serif;
}
#signUp-background{
    background: #4f4b5e;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
#signUp-content-container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#signUp-content{
    width: 70%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #5d5576;
    border-radius: 1.2rem;
    /* min-width: 1075px;; */
}

#signUp-content-inner-container{
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#signUp-content-inner{
    width: 80%;
    height: 70%;
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    justify-content: center;
    /* margin-top: 20px; */
    /* margin-left: 50px; */
    /* min-width: 587px; */
}

#signUp-content-inner-img-container{
    width: 250px;
    /* height: 30%; */
    margin-bottom: 35px;
}

#signUp-content-inner-img{
    width: 100%;
}

#signUp-content-inner-heading-container{
    width: 398px;
    /* height: 37%; */
    font-size: 20px;
    color: aliceblue;
    /* margin-bottom: 0px; */
}
#signUp-content-inner-sub-heading-container{
    /* height: 33%; */
    font-size: 20px;
    width: 398px;
    margin-top: 25px;
    color: aliceblue;
    font-size: 17px;
}

#signUp-content-form-card-container{
    width: 50%;
    height: 85%;
    display: flex;
    justify-content: center;
}

#signUp-content-form-card{
    display: flex;
    align-items: center;
    justify-content:space-around;
    flex-direction: column;
    width: 80%;
    height: 100%;
    background-color: white;
    border-radius: 1.2rem;
    min-width: 415px;
}

#signUp-content-form-card-inner{
    height: 80%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

#signUp-form-card-heading-container{
    width:80%;
    height: 20%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

#signUp-form-card-heading{
    font-size: 20px;
    font-weight: 900;
    color: rgb(44, 41, 41);
}

#signUp-form-card-sub-heading{
    font-size: 14px;
    font-weight: 700;
    color: rgb(83, 81, 81);
    /* visibility: hidden; */
    display: none;
}

#signUp-form-card-input-container{
    height: 80%;
    width: 100%;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    flex-direction: column;
}

#signUp-form-card{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

#signUp-form-card-inputs{
    width: 100%;
    height: 70%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

#signUp-form-card-username-input-container{
    width: 80%;
    height: 25%;
    border-radius: 1.2rem;
}

#signUp-form-card-username-input{
    width: 100%;
    height: 100%;
    border-radius: 1.2rem;
    padding: 15px;
    border: 1px solid rgb(190, 186, 186);
    outline: none;
}

#signUp-form-card-email-input-container{
    width: 80%;
    height: 25%;
    border-radius: 1.2rem;
}

#signUp-form-card-email-input{
    width: 100%;
    height: 100%;
    border-radius: 1.2rem;
    padding: 15px;
    border: 1px solid rgb(190, 186, 186);
    outline: none;
}

#signUp-form-card-password-input-container{
    width: 80%;
    height: 25%;
    border-radius: 1.2rem;
}

#signUp-form-card-password-input{
    width: 100%;
    height: 100%;
    border-radius: 1.2rem;
    background-color: white;
    padding: 15px;
    border: 1px solid rgb(190, 186, 186);
    outline: none;
}

#signUp-form-card-username-input:focus{
    /* background-color: rgb(211, 230, 235); */
    border: 1px solid black;;
}
#signUp-form-card-password-input:focus{
    /* background-color: rgb(211, 230, 235); */
    border: 1px solid black;;
}
#signUp-form-card-email-input:focus{
    /* background-color: rgb(211, 230, 235); */
    border: 1px solid black;;
}
/* #form-card-password-input::placeholder{

} */


#signUp-form-card-footer{
    width: 90%;
    height: 16%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#signUp-form-card-already-user{
    font-size: 13.5px;
    font-weight: 600;
}

#signUp-form-card-already-user:hover{
    cursor: default !important;
}

#signUp-form-card-already-user-text{
    color: black;
    font-size: 13.5px;
    font-weight: 600;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#signUp-form-card-already-user-text:hover{
    cursor: default !important;
}
// #signUp-form-card-already-user-text:hover{

//     transform: translateY(-0.25em);
// }
#log-in-text{
    color: black;
    font-size: 13.5px;
    font-weight: 700;
    transition: transform 0.3s ease-in-out;
    // transition: text-decoration 0.3s ease-in-out;
}
#log-in-text:hover{
    text-decoration: underline;
    font-size: 13.5px;
    font-weight: 800;
    cursor: pointer;
    transform: translateY(-0.25em);
    // box-shadow: 0 0.5em 0.5em -0.4em black;
}
#signUp-singUp-form-card-already-user{
    width: 30%;
    height: 100%;
}

#signUp-form-card-signUp-button-container{
    width: 32%;
    height: 98%;
}

#signUp-form-card-signUp-button{
    width: 100%;
    height: 100%;
    border-radius: 1.5rem;
    outline: none !important;
    border: none;
    background: #24d0a1;
    color: white;
    font-weight: 700;
    font-size: 16px;
    transition: transform 0.3s ease-in-out;
}

#signUp-form-card-signUp-button:hover{
    cursor: pointer;
    box-shadow: 0 0.5em 0.5em -0.4em #24d0a1;
    transform: translateY(-0.25em);
}
/* input:-webkit-autofill {
    background-color: white !important;
    background-image:none !important;
    color: #000000 !important;
  } */

@media only screen and (max-width: 1543px) {
    #signUp-content{
        width: 90%;
    }
}
@media only screen and (max-width: 1333px) {
    #signUp-content-inner-container{
        width: 60%;
    }
}
@media only screen and (max-width: 1115px) {
    /* #signUp-content{
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    } */
    /* #signUp-content-inner-container{
        display: none;
    } */
    #signUp-content{
        width: 100%;
    }
    #signUp-content-inner-container{
        width: 60%;
    }
    #signUp-content-inner{
        width: 80%;
    }
    /* #signUp-content-inner-img-container{
        width: 50%;
    } */
  }

  @media only screen and (max-width: 870px) {
    #signUp-content{
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
#signUp-content-inner-container{
    width: 100%;
}
    #signUp-content-inner{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        /* margin-left: 220px; */
    }  
    #signUp-content-inner-img-container{
        width: 250px;
        display: flex;
        align-items: flex-start;
    }
    #signUp-content-inner-heading-container{width: 363px;
    text-align: center;}
    #signUp-content-inner-sub-heading-container{width: 363px;
    text-align: center;}
    #signUp-content-form-card-container{
        margin-bottom: 25px;
    }
  }

  #verify-email-background{
    background: #4f4b5e;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

#verify-email-card{
    display: flex;
    align-items: center;
    justify-content:space-around;
    flex-direction: column;
    width: 400px;
    height: 300px;
    background-color: white;
    border-radius: 1.2rem;
    /* min-width: 415px; */
}

#verify-email-card-inner{
    width: 80%;
    height: 70%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

#verify-email-card-heading{
    width: 100%;
    // color: black;
    /* height: 60%; */
    font-size: 25px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 40px;
    text-align: center;
}
#verify-email-card-content{
    text-align: center;
    width: 100%;
    height: 60%;
    font-size: 24px;
    font-weight: 400;
}


#verify-email-backend-background{
    background: #4f4b5e;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

#verify-email-backend-card{
    display: flex;
    align-items: center;
    justify-content:space-around;
    flex-direction: column;
    width: 400px;
    height: 300px;
    background-color: white;
    border-radius: 1.2rem;
    /* min-width: 415px; */
}

#verify-email-backend-card-inner{
    width: 80%;
    height: 60%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

#verify-email-backend-card-heading{
    width: 100%;
    // color: black;
    /* height: 60%; */
    font-size: 25px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 40px;
    text-align: center;
}
// #verify-email-backend-card-content{
//     text-align: center;
//     width: 100%;
//     height: 60%;
//     font-size: 24px;
//     font-weight: 400;
// }