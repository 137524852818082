:root {
  --blue: #5e72e4;
  --indigo: #5603ad;
  --purple: #8965e0;
  --pink: #f3a4b5;
  --red: #f5365c;
  --orange: #fb6340;
  --yellow: #ffd600;
  --green: #2dce89;
  --teal: #11cdef;
  --cyan: #2bffc6;
  --white: #fff;
  --gray: #8898aa;
  --gray-dark: #32325d;
  --light: #ced4da;
  --lighter: #e9ecef;
  --primary: #5e72e4;
  --secondary: #f7fafc;
  --success: #2dce89;
  --info: #11cdef;
  --warning: #fb6340;
  --danger: #f5365c;
  --light: #adb5bd;
  --dark: #212529;
  --default: #172b4d;
  --white: #fff;
  --neutral: #fff;
  --darker: black;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: Open Sans, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

figcaption,
footer,
header,
main,
nav,
section {
  display: block;
}

body {
  font-family: Open Sans, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  text-align: left;
  color: #525f7f;
  background-color: #f8f9fe;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ul ul {
  margin-bottom: 0;
}

dfn {
  font-style: italic;
}

strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

a {
  text-decoration: none;
  color: #5e72e4;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  text-decoration: none;
  color: #233dd2;
}

a:not([href]):not([tabindex]) {
  text-decoration: none;
  color: inherit;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  text-decoration: none;
  color: inherit;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

caption {
  padding-top: 1rem;
  padding-bottom: 1rem;
  caption-side: bottom;
  text-align: left;
  color: #8898aa;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

legend {
  font-size: 1.5rem;
  line-height: inherit;
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5rem;
  padding: 0;
  white-space: normal;
  color: inherit;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

[hidden] {
  display: none !important;
}

h1,
.h1 {
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  color: #32325d;
}

h1,
.h1 {
  font-size: 1.625rem;
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.form-control {
  font-size: 1rem;
  line-height: 1.5;
  display: block;
  width: 100%;
  height: calc(2.75rem + 2px);
  padding: 0.625rem 0.75rem;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  color: #8898aa;
  /* border: 1px solid #cad1d7; */
  border: 1px solid black;
  border-radius: 0.375rem;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  border: 0;
  background-color: transparent;
}

.form-control:focus {
  color: #8898aa;
  border-color: rgba(50, 151, 211, 0.25);
  outline: 0;
  background-color: #fff;
  box-shadow: none, none;
}

.form-control:-ms-input-placeholder {
  opacity: 1;
  color: #adb5bd;
}

.form-control::-ms-input-placeholder {
  opacity: 1;
  color: #adb5bd;
}

.form-control::placeholder {
  opacity: 1;
  color: #adb5bd;
}

.form-control:disabled,
.form-control[readonly] {
  opacity: 1;
  background-color: #e9ecef;
}

.form-group {
  margin-bottom: 1.5rem;
}

.btn {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  display: inline-block;
  padding: 0.625rem 1.25rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  border: 1px solid transparent;
  border-radius: 0.375rem;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover,
.btn:focus {
  text-decoration: none;
}

.btn:focus {
  outline: 0;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.btn:disabled {
  opacity: 0.65;
  box-shadow: none;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active {
  box-shadow: none;
}

.btn:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08),
    none;
}

.btn-primary {
  color: #fff;
  border-color: #5e72e4;
  background-color: #5e72e4;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-primary:hover {
  color: #fff;
  border-color: #5e72e4;
  background-color: #5e72e4;
}

.btn-primary:focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08),
    0 0 0 0 rgba(94, 114, 228, 0.5);
}

.btn-primary:disabled {
  color: #fff;
  border-color: #5e72e4;
  background-color: #5e72e4;
}

.btn-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  border-color: #5e72e4;
  background-color: #324cdd;
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none, 0 0 0 0 rgba(94, 114, 228, 0.5);
}

.btn-neutral {
  color: #212529;
  border-color: #fff;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-neutral:hover {
  color: #212529;
  border-color: white;
  background-color: white;
}

.btn-neutral:focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08),
    0 0 0 0 rgba(255, 255, 255, 0.5);
}

.btn-neutral:disabled {
  color: #212529;
  border-color: #fff;
  background-color: #fff;
}

.btn-neutral:not(:disabled):not(.disabled):active {
  color: #212529;
  border-color: white;
  background-color: #e6e6e6;
}

.btn-neutral:not(:disabled):not(.disabled):active:focus {
  box-shadow: none, 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.collapse:not(.show) {
  display: none;
}

.input-group {
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
}

.input-group > .form-control {
  position: relative;
  width: 1%;
  margin-bottom: 0;
  flex: 1 1 auto;
}

.input-group > .form-control + .form-control {
  margin-left: -1px;
}

.input-group > .form-control:focus {
  z-index: 3;
}

.input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend {
  display: flex;
}

.input-group-prepend .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-text {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
  margin-bottom: 0;
  padding: 0.625rem 0.75rem;
  text-align: center;
  white-space: nowrap;
  color: #adb5bd;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  background-color: #fff;
  align-items: center;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.75rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #5e72e4;
  box-shadow: none;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #5e72e4;
  box-shadow: none;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #8898aa;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
}

.custom-control-label::before {
  position: absolute;
  top: 0.125rem;
  left: -1.75rem;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  content: "";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  background-color: #fff;
  box-shadow: none;
}

.custom-control-label::after {
  position: absolute;
  top: 0.125rem;
  left: -1.75rem;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #5e72e4;
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  background-color: #5e72e4;
  box-shadow: none;
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=\http://www.w3.org/2000/svg\ viewBox=\0 0 4 4\%3E%3Cpath stroke=\%23fff\ d=\M0 2h4\/%3E%3C/svg%3E");
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(94, 114, 228, 0.5);
}

.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(94, 114, 228, 0.5);
}

.custom-control-label::before {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before {
    transition: none;
  }
}

.nav {
  display: flex;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  flex-wrap: wrap;
}

.nav-link {
  display: block;
  padding: 0.25rem 0.75rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.navbar {
  position: relative;
  display: flex;
  padding: 1rem 1rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar > .container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  font-size: 1.25rem;
  line-height: inherit;
  display: inline-block;
  margin-right: 1rem;
  padding-top: 0.0625rem;
  padding-bottom: 0.0625rem;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  font-size: 1.25rem;
  line-height: 1;
  padding: 0.25rem 0.75rem;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  background-color: transparent;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  content: "";
  vertical-align: middle;
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-md > .container {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

.navbar-dark .navbar-brand {
  color: rgba(255, 255, 255, 0.65);
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: rgba(255, 255, 255, 0.65);
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.95);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.65);
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.95);
  border-color: transparent;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.95)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
  background-color: #fff;
  background-clip: border-box;
}

.card-body {
  padding: 1.5rem;
  flex: 1 1 auto;
}

.card-header {
  margin-bottom: 0;
  padding: 1.25rem 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background-color: #fff;
}

.card-header:first-child {
  border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.bg-secondary {
  background-color: #f7fafc !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #d2e3ee !important;
}

.bg-default {
  background-color: #172b4d !important;
}

a.bg-default:hover,
a.bg-default:focus,
button.bg-default:hover,
button.bg-default:focus {
  background-color: #0b1526 !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border-0 {
  border: 0 !important;
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
}

.justify-content-center {
  justify-content: center !important;
}

.align-items-center {
  align-items: center !important;
}

@media (min-width: 1200px) {
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }
}

.shadow {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.mt--8 {
  margin-top: -8rem !important;
}

.mb-7 {
  margin-bottom: 6rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.py-7 {
  padding-top: 6rem !important;
}

.py-7 {
  padding-bottom: 6rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

@media (min-width: 992px) {
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .py-lg-8 {
    padding-top: 8rem !important;
  }

  .py-lg-8 {
    padding-bottom: 8rem !important;
  }
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
}

.font-weight-bold {
  font-weight: 600 !important;
}

.text-white {
  color: #fff !important;
}

.text-light {
  color: #adb5bd !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #919ca6 !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover,
a.text-white:focus {
  color: #e6e6e6 !important;
}

.text-muted {
  color: #8898aa !important;
}

@media print {
  *,
  *::before,
  *::after {
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  img {
    page-break-inside: avoid;
  }

  p {
    orphans: 3;
    widows: 3;
  }

  @page {
    size: a3;
  }

  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }
}

figcaption,
main {
  display: block;
}

main {
  overflow: hidden;
}

.bg-gradient-primary {
  background: linear-gradient(87deg, #5e72e4 0, #825ee4 100%) !important;
}

.bg-gradient-primary {
  background: linear-gradient(87deg, #5e72e4 0, #825ee4 100%) !important;
}

.fill-default {
  /* fill: #172b4d; */
  fill: #1e1e2f;
}

@keyframes floating-lg {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(15px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes floating {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes floating-sm {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(0px);
  }
}

[class*="shadow"] {
  transition: all 0.15s ease;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover,
a.text-white:focus {
  color: #e6e6e6 !important;
}

.text-light {
  color: #ced4da !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #b1bbc4 !important;
}

.btn {
  font-size: 0.875rem;
  position: relative;
  transition: all 0.15s ease;
  letter-spacing: 0.025em;
  text-transform: none;
  will-change: transform;
}

.btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.btn:not(:last-child) {
  margin-right: 0.5rem;
}

.btn i:not(:first-child),
.btn svg:not(:first-child) {
  margin-left: 0.5rem;
}

.btn i:not(:last-child),
.btn svg:not(:last-child) {
  margin-right: 0.5rem;
}

.input-group .btn {
  margin-right: 0;
  transform: translateY(0);
}

[class*="btn-outline-"] {
  border-width: 1px;
}

.btn-inner--icon i:not(.fa) {
  position: relative;
  top: 2px;
}

.btn-neutral {
  color: #5e72e4;
}

.btn-icon .btn-inner--icon img {
  width: 20px;
}

.btn-icon .btn-inner--text:not(:first-child) {
  margin-left: 0.75em;
}

.btn-icon .btn-inner--text:not(:last-child) {
  margin-right: 0.75em;
}

.main-content {
  position: relative;
  /* height: 100vh; */
}

.main-content .navbar-top {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  padding-right: 0 !important;
  padding-left: 0 !important;
  background-color: transparent;
}

.custom-checkbox .custom-control-input ~ .custom-control-label {
  font-size: 0.875rem;
  cursor: pointer;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #5e72e4;
}

.custom-checkbox
  .custom-control-input:disabled
  ~ .custom-control-label::before {
  border-color: #e9ecef;
}

.custom-checkbox .custom-control-input:disabled:checked::before {
  border-color: rgba(94, 114, 228, 0.5);
}

.custom-control-label::before {
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  border: 1px solid #cad1d7;
}

.custom-control-label span {
  position: relative;
  top: 2px;
}

.custom-control-label {
  margin-bottom: 0;
}

.custom-control-alternative .custom-control-label::before {
  border: 0;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}

.custom-control-alternative
  .custom-control-input:checked
  ~ .custom-control-label::before {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.custom-control-alternative
  .custom-control-input:active
  ~ .custom-control-label::before,
.custom-control-alternative
  .custom-control-input:focus
  ~ .custom-control-label::before {
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}

.custom-checkbox .custom-control-input ~ .custom-control-label {
  font-size: 0.875rem;
  cursor: pointer;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #5e72e4;
}

.custom-checkbox
  .custom-control-input:disabled
  ~ .custom-control-label::before {
  border-color: #e9ecef;
}

.custom-checkbox .custom-control-input:disabled:checked::before {
  border-color: rgba(94, 114, 228, 0.5);
}

.footer {
  padding: 2.5rem 0;
  /* background: #f7fafc; */
}

.footer .nav .nav-item .nav-link {
  color: #8898aa !important;
}

.footer .nav .nav-item .nav-link:hover {
  color: #525f7f !important;
}

.footer .copyright {
  font-size: 0.875rem;
}

.nav-footer .nav-link {
  font-size: 0.875rem;
}

.nav-footer .nav-item:last-child .nav-link {
  padding-right: 0;
}

.form-control {
  font-size: 0.875rem;
}

.form-control:focus:-ms-input-placeholder {
  /* color: #adb5bd; */
  color: black;
}

.form-control:focus::-ms-input-placeholder {
  color: #adb5bd;
  color: black;
}

.form-control:focus::placeholder {
  color: #adb5bd;
  color: black;
}

.input-group {
  transition: all 0.15s ease;
  border-radius: 0.375rem;
  box-shadow: none;
}

.input-group .form-control {
  box-shadow: none;
}

.input-group .form-control:not(:first-child) {
  padding-left: 0;
  border-left: 0;
}

.input-group .form-control:not(:last-child) {
  padding-right: 0;
  border-right: 0;
}

.input-group .form-control:focus {
  box-shadow: none;
}

.input-group-text {
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.input-group-alternative {
  transition: box-shadow 0.15s ease;
  border: 0;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}

.input-group-alternative .form-control,
.input-group-alternative .input-group-text {
  border: 0;
  box-shadow: none;
}

.header {
  position: relative;
}

.input-group {
  transition: all 0.15s ease;
  border-radius: 0.375rem;
  box-shadow: none;
}

.input-group .form-control {
  box-shadow: none;
}

.input-group .form-control:not(:first-child) {
  padding-left: 0;
  border-left: 0;
}

.input-group .form-control:not(:last-child) {
  padding-right: 0;
  border-right: 0;
}

.input-group .form-control:focus {
  box-shadow: none;
}

.input-group-text {
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.input-group-alternative {
  transition: box-shadow 0.15s ease;
  border: 0;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}

.input-group-alternative .form-control,
.input-group-alternative .input-group-text {
  border: 0;
  box-shadow: none;
}

.nav-link {
  color: #525f7f;
}

.nav-link:hover {
  color: #5e72e4;
}

.nav-link i.ni {
  position: relative;
  top: 2px;
}

.navbar-horizontal .navbar-nav .nav-link {
  font-size: 0.9rem;
  font-weight: 400;
  transition: all 0.15s linear;
  letter-spacing: 0;
  text-transform: normal;
}

@media screen and (prefers-reduced-motion: reduce) {
  .navbar-horizontal .navbar-nav .nav-link {
    transition: none;
  }
}

.navbar-horizontal .navbar-nav .nav-link .nav-link-inner--text {
  margin-left: 0.25rem;
}

.navbar-horizontal .navbar-brand {
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.05px;
  text-transform: uppercase;
}

.navbar-horizontal .navbar-brand img {
  height: 30px;
}

.navbar-horizontal .navbar-dark .navbar-brand {
  color: #fff;
}

@media (min-width: 992px) {
  .navbar-horizontal .navbar-nav .nav-item {
    margin-right: 0.5rem;
  }

  .navbar-horizontal .navbar-nav .nav-item [data-toggle="dropdown"]::after {
    transition: all 0.15s ease;
  }

  .navbar-horizontal .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0.375rem;
  }

  .navbar-horizontal .navbar-nav .nav-link i {
    margin-right: 0.625rem;
  }

  .navbar-horizontal .navbar-nav .nav-link-icon {
    font-size: 1rem;
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
    border-radius: 0.375rem;
  }

  .navbar-horizontal .navbar-nav .nav-link-icon i {
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  @keyframes show-navbar-dropdown {
    0% {
      transition: visibility 0.25s, opacity 0.25s, transform 0.25s;
      transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
      opacity: 0;
    }

    100% {
      transform: translate(0, 0);
      opacity: 1;
    }
  }

  @keyframes hide-navbar-dropdown {
    from {
      opacity: 1;
    }

    to {
      transform: translate(0, 10px);
      opacity: 0;
    }
  }
}

.navbar-collapse-header {
  display: none;
}

@media (max-width: 767.98px) {
  .navbar-nav .nav-link {
    padding: 0.625rem 0;
    color: #172b4d !important;
  }

  .navbar-collapse {
    position: absolute;
    z-index: 1050;
    top: 0;
    right: 0;
    left: 0;
    overflow-y: auto;
    width: calc(100% - 1.4rem);
    height: auto !important;
    margin: 0.7rem;
    opacity: 0;
  }

  .navbar-collapse .navbar-toggler {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 0;
    cursor: pointer;
  }

  .navbar-collapse .navbar-toggler span {
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    opacity: 1;
    border-radius: 2px;
    background: #283448;
  }

  .navbar-collapse .navbar-toggler :nth-child(1) {
    transform: rotate(135deg);
  }

  .navbar-collapse .navbar-toggler :nth-child(2) {
    transform: rotate(-135deg);
  }

  .navbar-collapse .navbar-collapse-header {
    display: block;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .navbar-collapse .collapse-brand img {
    height: 36px;
  }

  .navbar-collapse .collapse-close {
    text-align: right;
  }
}

@keyframes show-navbar-collapse {
  0% {
    transform: scale(0.95);
    transform-origin: 100% 0;
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes hide-navbar-collapse {
  from {
    transform: scale(1);
    transform-origin: 100% 0;
    opacity: 1;
  }

  to {
    transform: scale(0.95);
    opacity: 0;
  }
}

.separator {
  position: absolute;
  top: auto;
  right: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 150px;
  transform: translateZ(0);
  pointer-events: none;
}

.separator svg {
  position: absolute;
  pointer-events: none;
}

.separator-bottom {
  top: auto;
  bottom: 0;
}

.separator-bottom svg {
  bottom: 0;
}

.separator-skew {
  height: 60px;
}

@media (min-width: 1200px) {
  .separator-skew {
    height: 70px;
  }
}

p {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.7;
}

@media (max-width: 768px) {
  .btn {
    margin-bottom: 10px;
  }
}

#navbar .navbar {
  margin-bottom: 20px;
}

#navbar .navbar-toggler {
  pointer-events: none;
}

/* icons */

@font-face {
  font-family: "NucleoIcons";
  src: url("https://raw.githack.com/creativetimofficial/argon-dashboard/master/assets/fonts/nucleo/nucleo-icons.eot");
  src: url("https://raw.githack.com/creativetimofficial/argon-dashboard/master/assets/fonts/nucleo/nucleo-icons.eot")
      format("embedded-opentype"),
    url("https://raw.githack.com/creativetimofficial/argon-dashboard/master/assets/fonts/nucleo/nucleo-icons.woff2")
      format("woff2"),
    url("https://raw.githack.com/creativetimofficial/argon-dashboard/master/assets/fonts/nucleo/nucleo-icons.woff")
      format("woff"),
    url("https://raw.githack.com/creativetimofficial/argon-dashboard/master/assets/fonts/nucleo/nucleo-icons.ttf")
      format("truetype"),
    url("https://raw.githack.com/creativetimofficial/argon-dashboard/master/assets/fonts/nucleo/nucleo-icons.svg")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

.ni {
  display: inline-block;
  font: normal normal normal 14px/1 NucleoIcons;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@-webkit-keyframes nc-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes nc-spin {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes nc-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.ni-circle-08::before {
  content: "\ea27";
}

.ni-email-83::before {
  content: "\ea30";
}

.ni-key-25::before {
  content: "\ea3b";
}

.ni-lock-circle-open::before {
  content: "\ea3e";
}

.ni-planet::before {
  content: "\ea47";
}

.ni-single-02::before {
  content: "\ea4e";
}

@media (max-width: 768px) {
  .btn {
    margin-bottom: 10px;
  }
}

#navbar .navbar {
  margin-bottom: 20px;
}

#navbar .navbar-toggler {
  pointer-events: none;
}
