@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700;800;900&display=swap');
*{
    font-family:'Montserrat', sans-serif;
}

body{
    background-color: #4f4b5e;
}
#navbar{
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: %; */
    height: 80px;
    width: 100vw;
    background-color: #6f678a;
}

#navbar-header{
    height: 100%;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#navbar-links-container{
    width: 479px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* color: black; */
    margin-right: 150px;
}

#navbar-logo-container{
    width: 185px;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 80px;
}

#navbar-logo{
    width: 100%;
    height: 100%;
}



.navbar-links{
    margin-right: 20px;
    border-radius: 1.6rem;
    width: 25%;
    height: 50px;
    display: flex ;
    align-items: center ;
    justify-content: center ;
    font-size: 18px;
    font-weight: 700;
    transition: transform 0.4s ease-in-out, background-color 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
    // transition: background-color 0.4s ease-in-out;
    // transition: color 0.4s ease-in-out;
    div{
        color: white;
        transition: color 0.4s ease-in-out;
    }
    a{
        color: white;
        font-weight: 700;
        font-size: 18px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // margin: 10px 25px;
        transition: color 0.4s ease-in-out;
    }   
}

.navbar-links:hover{
    cursor: pointer;
    background-color: #66607c;
    transform: translateY(-5px);
    box-shadow: 0 0.5em 0.5em -0.4em #24d0a1; 
    div{
        color: #24d0a1;
    }
    a{
        color: #24d0a1;
    }
}


.navbar-links:last-child{
    margin-right: 30px;
}


.navbar-link-active{
    background-color: #66607c;
    div{
        color: #24d0a1;
        font-weight: 800;
    }
}



#about-us-section{
    width: 100vw;
    height: 91.5vh;
    display: flex;
}


#about-us-content-section{
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
}

#about-us-content-container{
    height: 60%;
    width: 70%;
    display: flex;
    /* align-items: center; */
    /* justify-content: space-between; */
    flex-direction: column;
    text-align: center;
}

#about-us-heading{
    /* vertical-align: middle; */
    width: 100%;
    margin-bottom: 2px;
    // height: 12%;
    font-size: 40px;
    font-weight: 900;
    color: azure;
    // margin-bottom: 15px;
}

#about-us-text{
    width: 100%;
    height: 65%;
    font-size: 22px;
    font-weight: 700;
    /* color: rgb(34, 33, 33); */
    color: azure;
    /* margin-top: 5px; */
    /* margin-bottom: 15px; */
}

#about-use-content-footer{
    display: flex;
    align-items: center;
    width: 100%;
    height: 20%;
    justify-content: flex-start;
}

#about-us-content-footer-text{
    color:black;
    font-size: 16px;
    font-weight: 700;
    margin-right: 50px;
}

#about-us-content-footer-button-container{
    width: 32%;
    height: 40%;
}

#about-us-content-footer-button{
    width: 100%;
    height: 100%;
    border-radius: 1.5rem;
    border: none;
    background: #24d0a1;
    color: white;
    font-weight: 700;
    font-size: 16px;
}

#about-us-content-footer-button:hover{
    cursor: pointer;
    box-shadow: 0 0.5em 0.5em -0.4em #24d0a1;
    transform: translateY(-0.25em);
}


#about-us-image-section{
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#about-us-image-container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 80%;
    width: 83%;
    // border-radius: 25px;
    margin-left: 50px;
}

#about-us-image{
    border-radius: 25px;
    width: 90%;
    height: 80%;
}


// * {
//     margin: 0;
//     padding: 0;
//     box-sizing: border-box;
// }

#FAQ-section{
    width: 100vw;
    height: 91.5vh;
    display: flex;
}


#FAQ-content-section{
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

#FAQ-content-container{
    height: 62%;
    width: 85%;
    display: flex;
    /* align-items: center; */
    /* justify-content: space-between; */
    flex-direction: column;
    // align-items: center;

}

#FAQ-heading{
    /* vertical-align: middle; */
    width: 100%;
    height: 12%;
    font-size: 35px;
    font-weight: 900;
    color: azure;
    text-align: center;
    margin: 0;
    padding: 0;
}

#FAQ-question-contianer{
    width: 100%;
    height: 65%;
    font-size: 20px;
    font-weight: 700;
    /* color: rgb(34, 33, 33); */
    color: azure;
}

.faq{
    max-width: 700px;
    margin-top: 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #fff;
    cursor: pointer;
}
.faq:first-child{
        max-width: 700px;
        margin-top: 0px;
        padding-bottom: 1rem;
        border-bottom: 2px solid #fff;
        cursor: pointer;
}

.question{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.question h3{
    font-size: 1.8rem;
    padding: 0;
    margin: 0;
}

.answer{
    max-height: 0;
    overflow: hidden;
    transition: max-height 1.4s;
}
.answer p{
    padding-top: 1rem;
    line-height: 1.6;
    font-size: 1.2rem;
}

.faq.active .answer{
    max-height: 300px;
    animation: fade 1s ease-in-out;
}

.faq.active svg{
    transform: rotate(180deg);
}

@keyframes fade{
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

svg{
    transition: transform 0.6s ease-in;
}

#FAQ-content-footer{
    display: flex;
    align-items: center;
    width: 100%;
    height: 20%;
    justify-content: flex-start;
}

#FAQ-content-footer-text{
    color:black;
    font-size: 16px;
    font-weight: 700;
    margin-right: 50px;
}

#FAQ-content-footer-button-container{
    width: 32%;
    height: 40%;
}

#FAQ-content-footer-button{
    width: 100%;
    height: 100%;
    border-radius: 1.5rem;
    border: none;
    background: #24d0a1;
    color: white;
    font-weight: 700;
    font-size: 16px;
}

#FAQ-content-footer-button:hover{
    cursor: pointer;
    box-shadow: 0 0.5em 0.5em -0.4em #24d0a1;
    transform: translateY(-0.25em);
}


#FAQ-image-section{
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#FAQ-image-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
    width: 83%;
    // border-radius: 25px;
    margin-left: 50px;
}

#FAQ-image{
    border-radius: 25px;
    width: 90%;
    height: 80%;
}